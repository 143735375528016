<template>
  <div class="tw-flex tw-px-6 tw-py-4 tw-w-full tw-flex-col">

  <div class="below ">
    <div class="tw-flex tw-w-full tw-flex-col" v-for="(passenger,index) in tripAndTravellerDetails.seats" :key="index">
      <p :style="{color: companyData.companyColor}" class="header my-4">Passenger <span v-if="index >0">{{index+1}}</span></p>
      <div class="tw-flex tw-w-full tw-flex-col">
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user" :color="companyData.companyColor"  />
          </div>
          <div  class="content">{{passenger.travellerDetail.firstName +" "+ passenger.travellerDetail.lastName}} </div>
        </div>
<!--        <div class="tw-flex tw-flex-row tw-items-center">-->
<!--          <div class="tw-mr-2">-->
<!--            <icons name="user-gender" :color="companyData.companyColor" />-->
<!--          </div>-->
<!--          <div  class="content">{{passenger.travellerDetail.gender}} </div>-->
<!--        </div>-->
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-phone-number"  :color="companyData.companyColor" />
          </div>
          <div  class="content">{{passenger.travellerDetail.phoneNumber}} </div>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-mr-2">
            <icons name="user-email" :color="companyData.companyColor" />
          </div>
          <div  class="content">{{passenger.travellerDetail.email}} </div>
        </div>
<!--        <div class="tw-flex tw-flex-row tw-items-center">-->
<!--          <div class="tw-mr-2">-->
<!--            <icons name="user-date-of-birth" :color="companyData.companyColor" />-->
<!--          </div>-->
<!--          <div  class="content" v-if="passenger.travellerDetail.dateOfBirth">{{getDateOfBirth(passenger.travellerDetail.dateOfBirth)}} </div>-->
<!--        </div>-->
<!--        <div class=" tw-flex tw-flex-row tw-items-center">-->
<!--          <div class="tw-mr-2">-->
<!--            <icons name="user-address" :color="companyData.companyColor" />-->
<!--          </div>-->
<!--          <div  class="content text-capitalize" v-if="passenger.travellerDetail.address">{{passenger.travellerDetail.address.houseNumber+" "+-->
<!--          passenger.travellerDetail.address.streetName+ ","+-->
<!--          passenger.travellerDetail.address.city+" "+passenger.travellerDetail.address.state+" "+-->
<!--          passenger.travellerDetail.address.country}} </div>-->
<!--        </div>-->
      </div>
    </div>

  </div>
<!--    <v-checkbox >-->
<!--      <template #label>-->
<!--        <h6> Add insurance plan to my total price </h6>-->
<!--      </template>-->
<!--    </v-checkbox>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Icons from "../Icons.vue";
import dayjs from "dayjs";
export default {
    name:"CheckoutPassengerSummary",
  components:{Icons},
data(){
    return{
    }
},
  methods:{
    getDateOfBirth(dateOfBirth){
      return dayjs(dateOfBirth).format("ll")
    }
  },
  computed:{
      ...mapGetters("tripAndTravellerDetails", ["tripAndTravellerDetails"]),
    ...mapGetters("companyProfile", ["companyData"])
  }

}
</script>

<style lang="scss" scoped>
.below {
   width: 100%;
   border: 2px dashed #000000;
   padding: 40px;
   background: #F2F2F4;
    }

.header{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #004AAD;

}
.content{
    
font-family: 'Inter',sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: rgba(0, 17, 39, 0.73);
  padding: 0.5rem 0;
}
</style>