<template>
<div class="receipt-container" id="output">
  <img :src="booking.transportCompanyLogo" alt="background" class="receipt-background"/>

  <div class="receipt-body">
    <img :src="booking.transportCompanyLogo" alt="background" class="receipt-logo"/>
    <h6 class="receipt-company-header text-uppercase">{{booking.transportCompany}}</h6>
    <div class="tw-flex tw-w-full tw-justify-start tw-items-start tw-mb-3 tw-flex-col">
      <h6 class="receipt-header tw-mt-3">Booking Receipt</h6>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-1">
        <h6 class="booking-detail-header tw-w-4/12">Booking Ref ID: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase" style="text-transform: uppercase !important;">{{booking.bookingRefId}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <h6 class="booking-detail-header tw-w-4/12">Departure: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{booking.departure}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-pb-1">
        <h6 class="booking-detail-header tw-w-4/12">Arrival: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{booking.arrival}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center ">
        <h6 class="booking-detail-header tw-w-4/12">Vehicle No: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{booking.fleetRegistrationNumber}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <h6 class="booking-detail-header tw-w-4/12">Seat(s) Number: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{getSelectedSeats(booking.selectedSeats)}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center ">
        <h6 class="booking-detail-header tw-w-4/12">Trip Fare: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">&#8358; {{formatNumber(booking.tripFare / 100)}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-pb-1">
        <h6 class="booking-detail-header tw-w-4/12">Trip Date & Time: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{getDateTime(booking.tripDate)+ " "  +getTime(booking.tripDate,booking.tripTime)}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center ">
        <h6 class="booking-detail-header tw-w-4/12">Booked By: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{booking.bookedBy}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-pb-1">
        <h6 class="booking-detail-header tw-w-4/12">Departure A.: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{booking.departureTerminalAddress}}</h6>
      </div>

      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <h6 class="booking-detail-header tw-w-4/12">Time of Booking: &nbsp;</h6>
        <h6 class="booking-detail-desc text-uppercase">{{getDateTimeFull(booking.createdAt)}}</h6>
      </div>
      <div class="tw-flex tw-w-full tw-items-center tw-pb-1">
        <h6 class="booking-detail-desc text-capitalize">
          <b>Disclaimer:</b>
          Nomadicpod, functions solely for allowing commuters book trips of transport service providers and guaranteeing them safe on boarding
          to TSP vehicles ones they have completed a booking process with the service provider and obtained a booking reference through the Nomadicpod platform or mobile app.
          Please note, all responsibility of service delivery after booking, lies with the Transport company.
          <br/>
          Please be advised that in the event that the vehicle is not fully booked at {{booking.departure}}, we may need to make some adjustments to your trip.
          This could involve a change of vehicle or merging you with other passengers from our nearby terminal.
          We will do our best to ensure that any necessary changes are communicated to you in a timely and courteous manner.
        </h6>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <vue-barcode format="CODE128" font-size="12" font="Inter" :value="booking.bookingRefId" height="30" />
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-justify-start tw-pb-1">
        <h6 class="booking-detail-header">Powered by <span style="color: #004aad">Nomadicpod</span></h6>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import dayjs from "dayjs";
import VueBarcode from 'vue-barcode';

export default {
  name: "BookingReceipt",
  components:{VueBarcode},
  props:{
   booking:{},

  },
  data(){
    return {}
  },
  mounted() {
    this.print()
  },
  methods:{
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
    },
    getTime(date,time){
      return dayjs(date+time).format('LT')
    },
    getDateTime(date){
      return dayjs(date).format('ll')
    },
    getDateTimeFull(date){
      return dayjs(date).format('lll')
    },
    getSelectedSeats(seats){
      let returnSeats = ""
      seats.forEach(seat =>{
        returnSeats = seat.seatNumber+" "
      })
      return returnSeats
    },
    print () {
      const prtHtml = document.getElementById('output').innerHTML;
      // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
              stylesHtml += node.outerHTML;
            }
      // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html>
                                        <html lang="en">
                                          <head>
                                            ${stylesHtml}
                                          <title>Print Booking Receipt</title></head>
                                          <body>
                                            ${prtHtml}
                                          </body>
                                        </html>`
            );

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    }
  }
}
</script>

<style scoped lang="scss">
.receipt-background{
  height: auto;
  width: auto;
  position: absolute;
  z-index: 0;
  opacity: 0.05;
}

.receipt-container{
  width: 100%;
  height: auto;
  position: relative;
  background: white;
  align-items: center;
  display: none;
  @media print {
    display: flex;
  }
}

.receipt-body{
  width: 100%;
  height: 100%;
  background-color: white;
  padding-left: 2rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}

.receipt-logo{
  width: 100px;
  height: 50px;
}
.receipt-company-header{
  font-family: Poppins,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: #4F4F4F;
}

.receipt-header{
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  text-decoration-style: solid !important;
  text-decoration: underline black !important;
}

.booking-detail-header{
  font-family: Poppins,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  color: #4F4F4F;
}
.booking-detail-desc{
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  color: #4F4F4F;
  //text-transform: capitalize !important;
}
</style>